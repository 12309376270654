import { graphql } from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { CAREER } from "../../helpers/route";
import OpportunityDetailScene from "../../scenes/Opportunity/components/OpportunityDetails/OpportunityDetails";

export default function Opportunity(props) {
  return (
    <Layout
      navbarTheme="dark"
      currentRoute={CAREER}
      data={props.data.contentfulMenuLabel}
    >
      <Seo
        title={props.data.contentfulOpportunity.poste}
        description={props.data.contentfulOpportunity.descriptionMetadata}
      />
      <OpportunityDetailScene
        contentfulOpportunity={props.data.contentfulOpportunity}
        contentfulOpportunityPage={props.data.contentfulOpportunityPage}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query MyQuery($locale: String!, $url: String!) {
    contentfulOpportunity(url: { eq: $url }, node_locale: { eq: $locale }) {
      url
      id
      teletravail
      poste
      descriptionMetadata
      localisation
      heroDetailsText
      contentMethods
      contentTechnique
      contentClients
      contentSoft {
        raw
      }
      contentHard {
        raw
      }
      description {
        raw
      }
      image {
        id
        gatsbyImageData
      }
    }
    contentfulOpportunityPage(node_locale: { eq: $locale }) {
      detailsJoinUsTitle
      detailsRecrutingTitle
      detailsAvantagesTitle
      detailsKeySkillsTitle
      detailsHard
      detailsSoft
      detailsContentInfoTitle
      detailsDescriptionTitle
      detailsRemoteInfo
      detailsHeroInfoTitle
      returnOpportunity
      answerOpportunity
      detailsAvantagesText {
        raw
      }
      detailsJoinUsText {
        raw
      }
      detailsRecrutingText {
        raw
      }
      detailsKnowMore {
        raw
      }
      node_locale
    }
    contentfulMenuLabel(node_locale: { eq: $locale }) {
      adventure
      career
      cv
      identity
      innovation
      insideZenity
      inspirations
      node_locale
      recrute
      univers
      contact
      accept
      decline
      cookie
      knowMore
      formation
      ourFormation
      zac
      services
      zenityFactory
    }
  }
`;
