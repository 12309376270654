import { Typography, useMediaQuery } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React, { useState } from "react";

import { Footer } from "../../../../components/Footer/Footer";
import { SlideTransition } from "../../../../components/SlideTransition/SlideTransition";
import { localeLink } from "../../../../helpers/localeLink";
import { OPPORTUNITY } from "../../../../helpers/route";
import arrow from "../../../../resources/images/opportunity-misc/arrow.svg";
import ping from "../../../../resources/images/opportunity-misc/ping.svg";
import suitecase from "../../../../resources/images/opportunity-misc/suitecase.svg";
import MatchYourResume from "../../../MatchYourResume/MatchYourResume";
import { Hero } from "../../components/Hero/Hero";
import { Triangle } from "../Triangle/Triangle";
import * as styles from "./OpportunityDetails.module.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  () => ({
    flexDirection: "row-reverse",
    padding: "0 0px",
    ["@media (max-width:  1199.98px)"]: {
      padding: "0 0px",
    },
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      ["@media (max-width: 575.98px)"]: {
        margin: 0,
      },
    },
    "& .MuiAccordionSummary-content > .MuiTypography-body1": {
      fontFamily: "Futura",
      fontWeight: "100",
      fontSize: 24,
      letterSpacing: "0.04em",
      color: "#8F8F8F",
      borderBottom: "4px solid rgb(236 236 236)",
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "0px 0px 0px 0px",
  fontFamily: "Futura",
  fontSize: 16,
  lineHeight: "150%",
  color: "#676767",
  ["@media (max-width: 1199.98px)"]: {
    padding: "0px 0px 0px 0px",
  },
}));

export default function OpportunityDetails({
  contentfulOpportunity,
  contentfulOpportunityPage,
}) {
  const [openMatchYourResume, setOpenMatchYourResume] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [active, setActive] = useState(false);
  const dummy = "dummy";
  const sm = useMediaQuery("(max-width: 767.98px)");

  const handleCloseMatchYourResume = () => {
    setOpenMatchYourResume(false);
  };

  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const details = [
    contentfulOpportunity.contentTechnique,
    contentfulOpportunity.contentMethods,
    contentfulOpportunity.contentClients,
  ];

  return contentfulOpportunity ? (
    <>
      <Hero />
      <div className={styles.root}>
        <div className={styles.container}>
          <div className={styles.back}>
            <Link to={localeLink(OPPORTUNITY)}>
              <img src={arrow} alt="ping" />
              <span>{contentfulOpportunityPage.returnOpportunity}</span>
            </Link>
          </div>
          <div
            className={clsx(
              styles.headContainer,
              contentfulOpportunity.poste.length > 20 &&
                styles.headContainerLarge
            )}
          >
            <div>
              <GatsbyImage
                image={contentfulOpportunity.image.gatsbyImageData}
                alt="main"
              />
            </div>
            <div>
              <div>
                <div className={styles.poste}>
                  {contentfulOpportunity.poste}
                </div>
                <div className={styles.zone}>
                  <div className={styles.ping}>
                    <img className={styles.pingIcone} src={ping} alt="ping" />
                  </div>
                  <div className={styles.localisation}>
                    {contentfulOpportunity.localisation.trim()}
                  </div>
                </div>
                <div className={styles.zone}>
                  <div className={styles.ping}>
                    <img
                      className={styles.pingIcone}
                      src={suitecase}
                      alt="suitecase"
                    />
                  </div>
                  <div className={styles.localisation}>
                    {contentfulOpportunityPage.detailsRemoteInfo.trim()}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.matchResume}>
              <div className={clsx(styles.bottomButton, styles.matchResume)}>
                <div
                  className={styles.simpleBorderButton}
                  onClick={() => setOpenMatchYourResume(true)}
                >
                  {contentfulOpportunityPage.answerOpportunity}
                </div>
              </div>
              <div className={styles.heroDetails}>
                {contentfulOpportunityPage.detailsHeroInfoTitle.map(
                  (title, i) => {
                    return (
                      <div key={title + i}>
                        <div>{title}</div>
                        <div>{contentfulOpportunity.heroDetailsText[i]}</div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className={styles.mainContainer}>
            <div className={styles.sideBar}>
              {contentfulOpportunityPage.detailsContentInfoTitle.map(
                (title, i) => {
                  return (
                    details[i][0] !== dummy && (
                      <div key={i + title} className={styles.moreInfo}>
                        <div>{title}</div>
                        <div>
                          {details[i].map((data, i) => {
                            return <div key={data + i}>{data}</div>;
                          })}
                        </div>
                      </div>
                    )
                  );
                }
              )}
            </div>
            <div className={styles.bodyContainer}>
              <div className={styles.descriptionTitle}>
                {contentfulOpportunityPage.detailsDescriptionTitle}
              </div>
              <div className={styles.description}>
                <div>{renderRichText(contentfulOpportunity.description)}</div>
              </div>

              <div className={styles.accordion}>
                <Accordion
                  sx={expanded === "panel1" ? { overflow: "auto" } : ""}
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography
                      className={clsx(
                        expanded === "panel1" && !sm && styles.titleActive,
                        expanded === "panel1" && sm && styles.titleActiveMobile
                      )}
                    >
                      {contentfulOpportunityPage.detailsKeySkillsTitle}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={styles.switchSkills}>
                      <div
                        onClick={() => {
                          setActive(!active);
                        }}
                        className={clsx(
                          !active
                            ? styles.switchSkillsActive
                            : styles.switchSkillNotActive
                        )}
                      >
                        {contentfulOpportunityPage.detailsHard}
                      </div>
                      <div
                        onClick={() => {
                          setActive(!active);
                        }}
                        className={clsx(
                          active
                            ? styles.switchSkillsActive
                            : styles.switchSkillNotActive
                        )}
                      >
                        {contentfulOpportunityPage.detailsSoft}
                      </div>
                    </div>
                    <div className={styles.keySkills}>
                      {renderRichText(
                        active
                          ? contentfulOpportunity.contentSoft
                          : contentfulOpportunity.contentHard
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={expanded === "panel2" ? { overflow: "auto" } : ""}
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography
                      className={clsx(
                        expanded === "panel2" && !sm && styles.titleActive,
                        expanded === "panel2" && sm && styles.titleActiveMobile
                      )}
                    >
                      {contentfulOpportunityPage.detailsJoinUsTitle}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {renderRichText(
                      contentfulOpportunityPage.detailsJoinUsText
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={expanded === "panel3" ? { overflow: "auto" } : ""}
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography
                      className={clsx(
                        expanded === "panel3" && !sm && styles.titleActive,
                        expanded === "panel3" && sm && styles.titleActiveMobile
                      )}
                    >
                      {contentfulOpportunityPage.detailsRecrutingTitle}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {renderRichText(
                      contentfulOpportunityPage.detailsRecrutingText
                    )}
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={expanded === "panel4" ? { overflow: "auto" } : ""}
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                  >
                    <Typography
                      className={clsx(
                        expanded === "panel4" && !sm && styles.titleActive,
                        expanded === "panel4" && sm && styles.titleActiveMobile
                      )}
                    >
                      {contentfulOpportunityPage.detailsAvantagesTitle}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {renderRichText(
                      contentfulOpportunityPage.detailsAvantagesText
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
              <div className={styles.underline}>
                {renderRichText(contentfulOpportunityPage.detailsKnowMore)}
              </div>
            </div>
            <div className={clsx(styles.bottomButton, styles.matchResume)}>
              <div
                className={styles.simpleBorderButton}
                onClick={() => setOpenMatchYourResume(true)}
              >
                {contentfulOpportunityPage.answerOpportunity}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.triangleFrame}>
          <SlideTransition thresholds={0}>
            <Triangle />
          </SlideTransition>
        </div>
      </div>
      <div className={styles.footer}>
        <Footer
          background={`linear-gradient(206.5deg, rgb(149 109 81 / 8%) -14.94%, rgb(42 59 62 / 48%) 88.71%)`}
          lang={contentfulOpportunity.node_locale}
        />
      </div>
      <MatchYourResume
        open={openMatchYourResume}
        handleClose={handleCloseMatchYourResume}
        job={contentfulOpportunity.poste}
        lang={contentfulOpportunity.node_locale}
      />
    </>
  ) : (
    <>Aucune offre</>
  );
}
