// extracted by mini-css-extract-plugin
export var accordion = "OpportunityDetails-module--accordion--75591";
export var back = "OpportunityDetails-module--back--b44f6";
export var basicText = "OpportunityDetails-module--basicText--e2b95";
export var bodyContainer = "OpportunityDetails-module--bodyContainer--58d3c";
export var bottomButton = "OpportunityDetails-module--bottomButton--21ff7";
export var container = "OpportunityDetails-module--container--4205a";
export var content = "OpportunityDetails-module--content--b812d";
export var description = "OpportunityDetails-module--description--7b78b";
export var descriptionTitle = "OpportunityDetails-module--descriptionTitle--aa425";
export var firstTile = "OpportunityDetails-module--firstTile--0f226";
export var footer = "OpportunityDetails-module--footer--6ee76";
export var headContainer = "OpportunityDetails-module--headContainer--5afb0";
export var headContainerLarge = "OpportunityDetails-module--headContainerLarge--0a250";
export var headTitle = "OpportunityDetails-module--headTitle--11cf4";
export var heroDetails = "OpportunityDetails-module--heroDetails--d9bf7";
export var keySkills = "OpportunityDetails-module--keySkills--58e57";
export var localisation = "OpportunityDetails-module--localisation--1733e";
export var mainContainer = "OpportunityDetails-module--mainContainer--a6d87";
export var matchResume = "OpportunityDetails-module--matchResume--338db";
export var moreInfo = "OpportunityDetails-module--moreInfo--ced99";
export var ping = "OpportunityDetails-module--ping--ec735";
export var pingIcone = "OpportunityDetails-module--pingIcone--acb52";
export var poste = "OpportunityDetails-module--poste--f4753";
export var root = "OpportunityDetails-module--root--57d7c";
export var scroll = "OpportunityDetails-module--scroll--89a83";
export var sideBar = "OpportunityDetails-module--sideBar--31b8c";
export var simpleBorderButton = "OpportunityDetails-module--simpleBorderButton--8ac8b";
export var subTitle = "OpportunityDetails-module--subTitle--a7200";
export var switchSkillNotActive = "OpportunityDetails-module--switchSkillNotActive--74687";
export var switchSkills = "OpportunityDetails-module--switchSkills--c6364";
export var switchSkillsActive = "OpportunityDetails-module--switchSkillsActive--b2561";
export var titleActive = "OpportunityDetails-module--titleActive--7560b";
export var titleActiveMobile = "OpportunityDetails-module--titleActiveMobile--a88dc";
export var triangleFrame = "OpportunityDetails-module--triangleFrame--b4aa1";
export var underline = "OpportunityDetails-module--underline--056d6";
export var zone = "OpportunityDetails-module--zone--4cf20";